<template>
  <Teleport to="body">
    <div :class="viewOverlay ? 'overlay' : ''"></div>
  </Teleport>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    viewOverlay: {
      type: Boolean,
      required: true
    }
  },
  setup(props) {
    // todo: руками подставил false, должно быть - viewOverlay: props.viewOverlay
    return {
      viewOverlay: false,
    };
  }
});
</script>
